// Variables
$small: 575px;
$medium: 767px;
$large: 991px;
$extra-large: 1199px;


a.disabled {
	pointer-events: none;
}

img.img-centered {
	margin-left: auto;
	margin-right: auto;
	display: block;
}

pre.highlight {
	max-height: 30rem;
}

// Post jumbotron
div.jumbotron-post {
	padding: 0;
	margin-bottom: 2em;
	margin-top: 2em;
	position: relative;
	overflow: hidden;

	@media screen and (min-width: $small) {
		box-shadow: 0 0 10px 0px gray;
	}

	>.img-post {
		left: 50%;
		top: 50%;
		width: 100%;
		transform: translate(-50%, -50%);
		position: absolute;
	}
	>.img-post-helper {
		width: 100%;
		max-height: 10em;
		visibility: hidden;
	}
}

.table th, .table td {
	@media screen and (max-width: $medium) {
		padding: 0.2em !important;
	}
}

.jumbotron-post-text {
	text-shadow: 0 0 2px white;

	a > img {
		height: 1em;
	}
}

.post-navigation {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&.previous-post {
		text-align: left;
		padding-right: 1em;
	}

	&.next-post {
		text-align: right;
		padding-left: 1em;
	}
}

// Text lists
ol, ul {
	@media screen and (max-width: $small)  {
		padding-left: 1em;
	}
}

ol {
	ol {
		list-style-type: lower-roman;
		ol {
			list-style-type: lower-greek;
			ol {
				list-style-type: lower-latin;
			}
		}
	}
}

ol ol,ul ul {
	@media screen and (max-width: $small) {
		padding-left: 1em;
		margin-left: -1em;
	}
}

.tag.mini-margin,.category.mini-margin {
	margin-left:  0.05rem;
	margin-right: 0.05rem;
}

blockquote {
	font-size: 1em;
	font-style: italic;
	font-family: georgia, serif;
	line-height: 1.5em;
	padding: 0.5em 0.5em 0.5em 2em;
	min-height: 3em;
	background: #F2E7E0;
	border-left: 0.25em solid #ccc5c0;

	@media screen and (max-width: $small) {
		margin-left: 0 !important;
	}

	// Blockquote is italic, so emphasis is "normal"
	em {
		font-style: normal;
	}

	p:first-child {
		display: inline;
	}

	p {
		margin-top: 0px;
		margin-bottom: 0px;
	}
}

// Color special links on hover
// (Several layers of links that are revealed/hidden on hover, see footer_links.html)
a {
	&:hover {
		.filter-color-inactive-link {
			opacity: 0;
		}

		.filter-color-active-link {
			opacity: 1;
		}
	}

	> div.fade-image {
		position: relative;
		width: 2em;
		height: 2em;
		display: inline-block;
	}

	.filter-color-inactive-link, .filter-color-active-link {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: opacity 0.3s ease;
	}

	.filter-color-inactive-link {
		opacity: 1;
	}

	.filter-color-active-link {
		opacity: 0;
	}
}
.show-when-light {
	display: inherit;
}
.show-when-dark {
	display: none;
}


// Dark mode specific styling
[data-bs-theme="dark"] {
	$footer-color:#201f1c;
	$text-color: rgb(198, 188, 183);
	$light-text-color: rgb(96, 90, 88);
	--bs-body-bg: #111 !important;
	--bs-tertiary-bg: rgb(32, 31, 28) !important;
	--bs-tertiary-bg-rgb: 32, 31, 28 !important;

	.show-when-light {
		display: none;
	}
	.show-when-dark {
		display: inherit;
	}

	// Header and footer
	a {
		.filter-color-inactive-link.dark {
			opacity: 1;
		}
		.filter-color-active-link.dark {
			opacity: 0;
		}
	}

	background-color: #111 !important;
	color: $text-color;

	--bs-body-color: $text-color !important;
	--bs-heading-color: $text-color !important;

	.card-title a {
		color: $text-color;
		&:hover {
			color: #5b6d81;
		}
	}

	.bg-dark, .btn-dark {
		background-color: rgb(52, 58, 64) !important;
	}
	.bg-info, .btn-info {
		background-color: #2b7d8b !important;
		border-color: #2b7d8b !important;
	}
	.bg-white {
		background-color: #000 !important;
	}

	.bg-black {
		background-color: #eee !important;
	}

	.highlight {
		color: #bbc1c7 !important;
		background-color: #2a2a2a !important;
	}

	.nv {
		color: #423efb !important;
	}

	.card {
		background-color: #1a1a1a !important;
	}

	.codecaption {
		margin-bottom: 0;
	}

	blockquote {
		color: rgba(200, 193, 160, 0.923);
		background: #3a3735;
		border-left: 0.25em solid #504d4b;
	}

	figure {
		text-align: center;
		margin: auto;
		margin-bottom: 1em;

		figurecaption {
			font-style: italic;
		}
	}

	/* Previous/next buttons */
	.post-navigation > a {
	color: rgb(198, 183, 159);
	}

	.jumbotron-post-text {
		text-shadow: 0 0 2px black;
	}

	code|table {
		color: rgb(198, 183, 159);
	}

	.table-bordered td {
		color: rgb(198, 183, 159);
	}
}